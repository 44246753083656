import React from 'react'
import TextSection from 'src/components/TextSection'
import Columns from 'src/components/Columns'
import WideMedia from 'src/components/WideMedia'
import FiftyFifty from 'src/components/FiftyFifty'
import CocktailList from 'src/components/CocktailList'
import FeaturedCocktails from 'src/components/FeaturedCocktails'
import ReviewSlideshow from 'src/components/ReviewSlideshow'
import FeaturedProduct from 'src/components/FeaturedProduct'
import MerchList from 'src/components/MerchList'
import InstagramFeed from 'src/components/InstagramFeed'
import ParallaxSection from 'src/components/ParallaxSection'
import SustainabilityBlock from 'src/components/SustainabilityBlock'
import StockistMap from 'src/components/StockistMap'

const componentMap = {
	ContentfulTextSection: TextSection,
	ContentfulColumns: Columns,
	ContentfulFiftyFifty: FiftyFifty,
	ContentfulWideMedia: WideMedia,
	ContentfulCocktailList: CocktailList,
	ContentfulMerchList: MerchList,
	ContentfulFeaturedCocktails: FeaturedCocktails,
	ContentfulReviews: ReviewSlideshow,
	ContentfulFeaturedProduct: FeaturedProduct,
	ContentfulInstagramFeed: InstagramFeed,
	ContentfulParallaxSection: ParallaxSection,
	ContentfulSustainabiltyBlock: SustainabilityBlock,
	ContentfulStockistMap: StockistMap
}

export default ({ item, prevTheme, nextTheme, index, isHome, isLastSection, isFirstSection, theme, padBottom, prevFullWidth, nextFullWidth }) => {
	const Component = componentMap[item.__typename]
	if (!theme || theme === null) {
		theme = 'default'
	}
	if ((!prevTheme || prevTheme === null) && !isFirstSection && !prevFullWidth) {
		prevTheme = 'default'
	}
	if ((!nextTheme || nextTheme === null) && !isLastSection && !nextFullWidth) {
		nextTheme = 'default'
	}
	return Component ? (
		<Component
			{...item}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isLastSection={isLastSection}
			isFirstSection={isFirstSection}
			index={index}
			padBottom={padBottom}
			isHome={isHome}
		/>
	) : null
}
